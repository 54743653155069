import create from 'zustand';
import { useState } from 'react';
import { TCMSOrder, TCMSPipeline, TShopifyOrder } from 'type';
import { showPopupMessage } from 'components/elements/showPopupMessage';

import Client from './api-client';
import moment from 'moment';
import GlobalEvent from 'js-events-listener';
import ValHelper from 'helpers/ValHelper';
import RequestUtils from "store/api-client/Request.utils";
import { requestMicroApi } from 'helpers/RequestHelper';
import { Api } from 'store';
import { LOG_TYPES } from 'const';
// export const ENV = 'dev';
// export const MICRO_SERVICES_HOST = 'http://localhost:3421';
import { ENV as ENV1, MICRO_SERVICES_HOST as MICRO_SERVICES_HOST1 } from 'const';

export const ENV = ENV1;
export const MICRO_SERVICES_HOST = MICRO_SERVICES_HOST1;

export const ORDER_STATUS = ["Awaiting Payment", "Queued For Production", "Held", "Held By Admin", "On Time", "Delayed", "Manifested", "Delivered", "Rejected", "Cancelled"];
export const ORDER_STAGE = ["Pre Production", "In Production", "Fulfillment", "Cancelled"]
export const STATUS_BY_STAGE = {
  "Pre Production": ["Awaiting Payment", "Queued For Production", "Held", "Held By Admin"],
  "In Production": ["On Time", "Delayed", "Held"],
  "Fulfillment": ["Manifested", "Delivered"],
  "Cancelled": ["Rejected", "Cancelled"],
}

interface IOrderStore {
  clients: Array<{ 'Client Name': string, slug: string, 'Client ID': string, domain: string }>
  orders: {
    [orderId: string]: TCMSOrder,
  },
  pipelines: {
    [pipelineId: string]: TCMSPipeline,
  },
  getListOrderRaw(clientId: string, query: string, options: any): Promise<{ hasNext: boolean, list: Array<TCMSOrder>, total: number, error?: string, }>,
  getListOrder(p: number, status: string, dateRange: { startDate: any, endDate: any }, clientId: string, filters?: { filter?: any, sorter?: any }): Promise<{ hasNext: boolean, list: Array<TCMSOrder>, total: number, error?: string, }>,
  getDeletedOrders(p: number, clientId: string, filters?: { filter?: any, sorter?: any }): Promise<{ hasNext: boolean, list: Array<TCMSOrder>, total: number, error?: string, }>,
  search(p: number, filter: string, clientId: string, filters?: { filter?: any, sorter?: any }): Promise<{ hasNext: boolean, list: Array<TCMSOrder>, total: number, error?: string, }>,
  getListPipeline(p: number, dateRange: { startDate: any, endDate: any }): Promise<{ hasNext: boolean, list: Array<TCMSPipeline>, total: number, error?: string, }>,
  updateOrderStage(orders: TCMSOrder[], data: { Stage?: string, StageStatus?: string, Status?: string }, others?: { [key: string]: any }): Promise<void>,
  reportData: {
    acceptedOrderToday: number,
    pendingOrderToday: number,
    unpaidOrder: number,
    unpaidOrderThisYear: number,
    pendingOrder: number,
    fulfilledOrder: number,
    fulfilledByMonth: {
      [month: string]: number,
    },
    orderByMonth: {
      [month: string]: number,
    },
    totalOrder: number,
    totalOrderPrice: number,
    totalProcessedItemsValue: number,
    totalOrderValueMonthly: number,
    totalProcessedValueMonthly: number,
    countByClient: {
      [clientId: string]: { clientId: string, clientName: string, total?: number, fulfilled?: number }
    }
  },
  getChargeAmountOrderSample: (orders: TCMSOrder[]) => Promise<any>,
  chargeFromWalletByStripeInvoice: (orders: Array<TCMSOrder>, invoiceIds: Array<string>, shippingService: string, useSampleToken?: boolean) => Promise<any>,
  reRunJob: (params: {
    jobId: string | number,
    pipelineId: string | number,
    slug: string,
    thisJobOnly?: boolean,
    force?: boolean,
  }) => Promise<void>,
  updateReportDataManually: (data: any) => void,
  [otherKey: string]: any,
}

export const useOrderStore = create<IOrderStore>((set, get) => ({
  clients: [
    { 'Client ID': '440478626329', 'Client Name': 'Bottled Goose', slug: 'bg', domain: 'https://www.bottledgoose.co.uk', },

    // temporary stop PIW
    // { 'Client ID': '665847158329', 'Client Name': 'Partner in Wine', slug: 'piw', domain: 'https://www.partnerinwine.co.uk', },
    // remove legacy GHG client
    // { 'Client ID': '578953627077', 'Client Name': 'Great Harbouring Gifts', slug: 'ghg', domain: 'https://greatharbourgifts.com', },
  ],
  // @ts-ignore
  reportData: {
    pendingOrder: 0,
    acceptedOrderToday: 0,
    pendingOrderToday: 0,
    fulfilledOrder: 0,
    fulfilledByMonth: {},
    orderByMonth: {},
    totalOrder: 0,
    totalOrderPrice: 0,
    totalProcessedItemsValue: 0,
    totalOrderValueMonthly: 0,
    totalProcessedValueMonthly: 0,
    countByClient: {},
  },
  orders: {},
  pipelines: {},
  loadingReRun: false,
  getListOrderRaw: async (clientId = 'all', query, options = {}) => {
    const allClients = get().clients;
    const bgClient = allClients[0];
    const findClient = allClients.find(v => v['Client ID'] === clientId);
    const filterClients = clientId === 'all' ? get().clients : [findClient || bgClient];
    let env = ENV;
    let _query = query
      + `&env=${env}`;
    if (!findClient && Number(clientId)) _query += `&clientId=${clientId}`;
    let resArr = await Promise.all(filterClients.map(async (c) => {
      try {
        // const res = await requestMicroApi(`/api/${c.slug}/listOrder${_query}`);
        // const json = await res.json();
        const res = await RequestUtils.get(`/api/${c.slug}/listOrder${_query}`);
        const json = await res.data;
        const { list, pageInfo } = json.data;
        return {
          list: list.map(v => {
            return {
              ...v,
              ...c,
              'Client Name': v['Client Name'] || c['Client Name'],
              'Client ID': v['Client ID'] || c['Client ID'],
              ApiSlug: c.slug,
            }
          }),
          pageInfo
        };
      } catch (err) { }
    }));

    // save data and match pipelines
    const obj: IOrderStore['jobs'] = {};
    const pipelines = get().pipelines;
    const listAllPipelines = Object.keys(pipelines).map(OrderId => pipelines[OrderId]);

    await Promise.all(resArr.map(async (res) => {
      try {
        await Promise.all(res.list.map(async (j: TCMSOrder) => {
          try {
            obj[j['Order ID']] = j;
            if (!!j.Pipelines) return;
            const filterPipelines = listAllPipelines.filter(v => v.OrderId === j['Order ID']);
            j.Pipelines = filterPipelines;
            if (filterPipelines.length === 0) {
              const fetchPipelines = await get().getPipelinesByOrderId(j.ApiSlug, j['Order ID']);
              // console.log('fetchPipelines', fetchPipelines);
              j.Pipelines = fetchPipelines;
            }
          } catch (err) { }
        }));
      } catch (err) { }
    }));

    // resArr.forEach((res) => {
    //   res.list.forEach((j: TCMSOrder) => {
    //     obj[j['Order ID']] = j;
    //     const filterPipelines = listAllPipelines.filter(v => v.OrderId === j['Order ID']);
    //     j.Pipelines = filterPipelines;
    //   });
    // })

    set({ orders: { ...get().orders, ...obj } });
    const mergedList = (() => {
      if (resArr.length === 1) return resArr[0].list;
      // @ts-ignore
      return (resArr.reduce((a, b) => ({ list: [...a.list, ...b.list] }))).list;
    })();
    get().getRoyalMailOrderData(mergedList);
    return {
      hasNext: (() => {
        if (resArr.length === 1) return !resArr[0].pageInfo.isLastPage;
        // @ts-ignore
        return (resArr.reduce((a, b) => ({ pageInfo: { isLastPage: !a.pageInfo.isLastPage || !b.pageInfo.isLastPage ? false : true } }))).pageInfo.isLastPage === false;
      })(),
      total: (() => {
        if (resArr.length === 1) return resArr[0].pageInfo.totalRows;
        // @ts-ignore
        return (resArr.reduce((a, b) => ({ pageInfo: { totalRows: a.pageInfo.totalRows + b.pageInfo.totalRows } }))).pageInfo.totalRows;
      })(),
      list: mergedList,
    };
  },
  getListOrder: async (page = 1, stage, dateRange, clientId = 'all', filters) => {
    const params: any = {
      supported: true,
      offset: (page - 1) * 10,
      limit: 10,
    }
    if (stage) params.stage = stage;
    if (dateRange.startDate && dateRange.endDate) {
      params.startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
      params.endDate = moment(dateRange.endDate).format('YYYY-MM-DD');
      params.dateType = 'UpdatedAt';
    }
    if (filters?.filter) {
      Object.keys(filters.filter).forEach(key => {
        if (filters.filter[key]) {
          params[key] = filters.filter[key].join(',');
        }
      })
    }
    if (filters?.sorter?.order) {
      params.sort = `${filters.sorter.order === "descend" ? "-" : ""}${filters.sorter.columnKey}`
    }
    const query = `?${ValHelper.objectToQueryString(params)}`;
    return get().getListOrderRaw(clientId, query, {});
  },
  getDeletedOrders: async (page = 1, clientId = 'all', filters) => {
    const params: any = {
      inactive: true,
      offset: (page - 1) * 10,
      limit: 10,
    }
    if (filters?.filter) {
      Object.keys(filters.filter).forEach(key => {
        if (filters.filter[key]) {
          params[key] = filters.filter[key].join(',');
        }
      })
    }
    if (filters?.sorter?.order) {
      params.sort = `${filters.sorter.order === "descend" ? "-" : ""}${filters.sorter.columnKey}`
    }
    const query = `?${ValHelper.objectToQueryString(params)}`;
    return get().getListOrderRaw(clientId, query, {});
  },
  search: async (page, searchKey, clientId, filters) => {
    const params: any = {
      filter: searchKey.replace(/#/g, ""),
      offset: (page - 1) * 10,
      limit: 10,
    }
    if (filters?.filter) {
      Object.keys(filters.filter).forEach(key => {
        if (filters.filter[key]) {
          params[key] = filters.filter[key].join(',');
        }
      })
    }
    if (filters?.sorter?.order) {
      params.sort = `${filters.sorter.order === "descend" ? "-" : ""}${filters.sorter.columnKey}`
    }
    const query = `?${ValHelper.objectToQueryString(params)}`;
    return get().getListOrderRaw(clientId, query, {});
  },
  getListPipeline: async (page = 1, dateRange) => {
    const dateQuery = `${dateRange.startDate && dateRange.endDate ? `&startDate=${moment(dateRange.startDate).format('YYYY-MM-DD')}&endDate=${moment(dateRange.endDate).format('YYYY-MM-DD')}` : ''}`
    let query = `?offset=${(page - 1) * 100}&limit=100${dateQuery}`;
    const [res1] = await Promise.all([
      (async () => {
        const res = await requestMicroApi(`/api/bg/pipeline/list${query}`);
        const json = await res.json();
        const { list, pageInfo } = json.data;
        return { list, pageInfo };
      })(),
      // disable PIW and GHG
      // (async () => {
      //   const res = await fetch(`${MICRO_SERVICES_HOST}/api/ghg/pipeline/list${query}`, {
      //     method: 'get',
      //   });
      //   const json = await res.json();
      //   const { list, pageInfo } = json.data;
      //   return { list, pageInfo };
      // })(),
      // (async () => {
      //   const res = await fetch(`${MICRO_SERVICES_HOST}/api/piw/pipeline/list${query}`, {
      //     method: 'get',
      //   });
      //   const json = await res.json();
      //   const { list, pageInfo } = json.data;
      //   return { list, pageInfo };
      // })(),
    ])

    const obj: IOrderStore['pipelines'] = {}
    res1.list.forEach((j: TCMSPipeline) => {
      obj[j.OrderId] = j;
    });
    // res2.list.forEach((j: TCMSPipeline) => {
    //   obj[j.OrderId] = j;
    // });
    // res3.list.forEach((j: TCMSPipeline) => {
    //   obj[j.OrderId] = j;
    // });
    const newPipeliens = { ...get().pipelines, ...obj };
    set({ pipelines: newPipeliens });
    return {
      hasNext: res1.pageInfo.isLastPage,
      total: res1.pageInfo.totalRows,
      list: res1.list,
    };
  },
  getPipelinesOfAnOrder: async (orderId) => {
    const order = get().orders[orderId];
    if (!order) return [];
    const pipelines = get().pipelines;
    const arr = Object.keys(pipelines).map(Id => pipelines[Id]).filter(v => v.OrderId === orderId);
    // TODO: fetch more if arr.length = 0;
    return arr;
  },
  reRunJob: async (params) => {
    const { jobId, pipelineId, slug, thisJobOnly, force } = params;
    set({ loadingReRun: true });
    try {
      await requestMicroApi(`/api/${slug}/pipeline/rerun-job`, {
        method: 'post',
        body: JSON.stringify({
          jobId, pipelineId, thisJobOnly, force
        }),
      });
    } catch (error) {
      throw new Error(error);
    } finally {
      set({ loadingReRun: false });
    }
  },
  updateOrderStage: async (orders, data, others) => {
    await Promise.all(orders.map(async (order) => {
      const slug = order.ApiSlug || get().clients.find(c => order['Raw Data'].order_status_url?.includes(c.domain))?.slug || 'bg';
      await requestMicroApi(`/api/${slug}/updateOrderStatus?id=${order.Id}`, {
        method: 'post',
        body: JSON.stringify({
          ...data,
        }),
      });
    }))
    if (data.Status === "Fulfilled") {
      await Promise.all(orders.map(async (order) => {
        await Client.Api.Order.updateInvoice({
          orderId: String(order['Order ID']),
          fulfilledAt: moment().toISOString(),
        })
      }))
    }
    if (data.Status === "Rejected") {
      if (data.StageStatus === "Rejected") {
        await Client.Api.Order.rejectOrder({
          orders: orders.map(order => ({
            resellerId: order['Client ID'] || '440478626329',
            shopifyOrderId: order['Raw Data']?.id,
            OrderNumber: order['Order Number'],
            OrderId: order['Order ID'],
            StoreID: order['Store ID'],
          })),
          reason: others?.reason,
          deleteShopify: others?.deleteShopify || false,
        })
      } else if (data.StageStatus === "Cancelled") {
        await Client.Api.Order.cancelOrder({
          orders: orders.map(order => ({
            resellerId: order['Client ID'] || '440478626329',
            shopifyOrderId: order['Raw Data']?.id,
            OrderNumber: order['Order Number'],
            OrderId: order['Order ID'],
            StoreID: order['Store ID'],
          })),
          deleteShopify: others?.deleteShopify || false,
        })
      }
      if (others?.deleteShopify) {
        orders.forEach((order: TCMSOrder) => {
          if (!order["OrderType"] || order["OrderType"] === "Shopify") {
            get().updateShareData(order, {
              shopifyDeleted: true,
            })
          }
        })
      }
    }
  },
  orderReportAll: async (resellerId) => {
    // let clients = (!resellerId || resellerId === 'all')
    //   ? get().clients
    //   : [get().clients.find(v => v["Client ID"] === String(resellerId) || v.slug === resellerId)];
    let clients = [get().clients?.[0]];
    let queryByClient = `?env=${ENV}`;

    if (resellerId && resellerId !== 'all') {
      queryByClient += `&clientId=${resellerId}`;
    }

    const ress = await Promise.all(clients.map(async (client) => {
      try {
        const res = await requestMicroApi(`/api/${client.slug}/order-report${queryByClient}`);
        const json = await res.json();
        return json.data;
      } catch (err) { }
    }));

    const n = (num) => (!num || isNaN(num)) ? 0 : +num;

    const reportData = {
      acceptedOrder: 0,
      pendingOrder: 0,
      acceptedOrderToday: 0,
      pendingOrderToday: 0,
      fulfilledOrder: 0,
      fulfilledOrderWithIn28d: 0,
      fulfilledByMonth: {},
      orderByMonth: {},
      totalOrder: 0,
      totalOrderPrice: 0,
      totalProcessedItemsValue: 0,
      totalOrderValueMonthly: 0,
      totalProcessedValueMonthly: 0,
      countByClient: {},
      unpaidOrder: 0,
      unpaidOrderThisYear: 0,
    };

    ress.forEach(r => {
      reportData.acceptedOrderToday += n(r?.acceptedOrderToday);
      reportData.pendingOrderToday += n(r?.pendingOrderToday);
      reportData.acceptedOrder += n(r?.acceptedOrder);
      reportData.pendingOrder += n(r?.pendingOrder);
      reportData.unpaidOrder += n(r?.unpaidOrder);
      reportData.unpaidOrderThisYear += n(r?.unpaidOrderThisYear);
      reportData.fulfilledOrder += n(r?.fulfilledOrder);
      for (let month in (r?.fulfilledByMonth || {})) {
        if (r?.fulfilledByMonth?.[month]) {
          reportData.fulfilledByMonth[month] = n(reportData.fulfilledByMonth[month]) + n(r?.fulfilledByMonth?.[month]);
        }
      }

      for (let month in (r?.orderByMonth || {})) {
        if (r?.orderByMonth?.[month]) {
          reportData.orderByMonth[month] = n(reportData.orderByMonth[month]) + n(r?.orderByMonth?.[month]);
        }
      }
      reportData.fulfilledOrder += n(r?.fulfilledOrder);
      reportData.fulfilledOrderWithIn28d += n(r?.fulfilledOrderWithIn28d);
      reportData.totalOrderPrice += n(r?.totalOrderPrice);
      reportData.totalProcessedItemsValue += n(r?.totalProcessedItemsValue);
      reportData.totalOrder += n(r?.totalOrder);
      reportData.totalOrderValueMonthly += n(r?.totalOrderValueMonthly);
      reportData.totalProcessedValueMonthly += n(r?.totalProcessedValueMonthly);

      Object.keys(r?.countByClient || {}).forEach(clientId => {
        const data = reportData?.countByClient?.[clientId] || {};
        const newData = r?.countByClient?.[clientId] || {};
        data.clientId = clientId;
        if (newData.clientName) data.clientName = newData.clientName;
        data.total = (data.total || 0) + (newData.total || 0);
        data.fulfilled = (data.fulfilled || 0) + (newData.fulfilled || 0);
        reportData.countByClient[clientId] = data;
      });

    })

    set({ reportData });
  },
  orderReportClientByMonth: async (slug, month, clientId) => {
    const clients = (!slug || slug === 'all')
      ? get().clients
      : [get().clients.find(v => v.slug === slug)];
    if (!clients) return;

    const shouldFilterClientId = clientId && !get().clients.some(i => i['Client ID'] === clientId);
    const ress = await Promise.all(clients.map(async (client) => {
      try {
        const res = await requestMicroApi(`/api/${client.slug}/order-report-month?env=${ENV}&month=${month}${shouldFilterClientId ? `&clientId=${clientId}` : ''}`);
        const json = await res.json();
        return {
          ...json.data,
          client,
        };
      } catch (err) { }
    }));
    const data = {
      customers: [],
      orders: [],
      products: [],
    };
    ress.forEach(r => {
      data.customers = [...data?.customers, ...r?.customers]
        .sort((a, b) => moment(a.created_at).unix() - moment(b.created_at).unix())
        .map(i => ({
          ...i,
          clientId: i.clientId || r.client?.['Client ID'],
          clientName: i.clientName || r.client?.['Client Name'],
        }));
      data.orders = [...data.orders, ...r.orders]
        .sort((a, b) => moment(a.created_at).unix() - moment(b.created_at).unix())
        .map(i => ({
          ...i,
          "Client ID": i['Client ID'] || r.client?.['Client ID'],
          "Client Name": i['Client Name'] || r.client?.['Client Name'],
        }));
      data.products = [...data.products, ...r.products]
        .map(i => ({
          ...i,
          clientId: i.clientId || r.client?.['Client ID'],
          clientName: i.clientName || r.client?.['Client Name'],
        }));
    });
    return data;
  },
  getPipelinesByOrderId: async (slug, orderId) => {
    const res = await requestMicroApi(`/api/${slug}/pipeline/list?limit=10&offset=0&orderId=${orderId}`);
    const json = await res.json();
    const obj: IOrderStore['pipelines'] = {}
    json.data.list.forEach((j: TCMSPipeline) => {
      obj[j.OrderId] = j;
    });
    const newPipeliens = { ...get().pipelines, ...obj };
    set({ pipelines: newPipeliens });
    return json.data.list;
  },
  findByOrderNo: async (orderNo: string, clientId: string, orderId: string,) => {
    console.log('findByOrderNo', orderNo, clientId, orderId);
    const findClient = get().clients.find(v => v['Client ID'] === clientId);
    const slugs = clientId === 'all' ? get().clients.map(v => v.slug) : [findClient?.slug || 'bg'];
    const resArr = await Promise.all(slugs.map(async (slug) => {
      try {
        const res = await requestMicroApi(`/api/${slug}/findByOrderId?orderNo=${orderNo || ''}&orderId=${orderId || ''}`);
        const json = await res.json();
        if (json.data?.['Order ID']) {
          await get().getPipelinesByOrderId(slug, json.data?.['Order ID']);
        }
        return {
          ...json.data,
          ...findClient,
          ApiSlug: slug,
        }
      } catch (err) {
        console.log('findByOrderNo', err);
      }
    }));
    const list: Array<TCMSOrder> = resArr.filter(v => !!v && !!v.Id);
    const pipelines = get().pipelines;
    const listAllPipelines = Object.keys(pipelines).map(Id => pipelines[Id]);

    list.forEach((j: TCMSOrder) => {
      const filterPipelines = listAllPipelines.filter(v => v.OrderId === j['Order ID']);
      j.Pipelines = filterPipelines;
    });
    get().getRoyalMailOrderData(list);
    return list;
  },
  isInProduction: (order: TCMSOrder) => {
    const pipelines = order.Pipelines;
    if (pipelines.length === 0) return false;
    return !!pipelines[pipelines.length - 1].SharedData?.isInProduction;
  },
  updateShareData: async (order: TCMSOrder, data: any) => {
    const pipelines = order.Pipelines;
    if (pipelines.length === 0) return;
    // const slug = order.ApiSlug || get().clients.find(c => order['Raw Data'].order_status_url?.includes(c.domain))?.slug || 'bg';
    const json = await Api.Bg.updatePipelineShareDataApi({
      data,
      pipelineId: pipelines[pipelines.length - 1].Id,
    })
    await get().getPipelinesByOrderId(order.ApiSlug || 'bg', order['Order ID']);
    return json.data;
  },
  dispatchToRoyalMail: async (order: TCMSOrder) => {
    const slug = order.ApiSlug || get().clients.find(c => order['Raw Data'].order_status_url.includes(c.domain))?.slug || 'bg';
    const res = await requestMicroApi(`/api/${slug}/trigger-dispatch-royalmail?orderId=${order['Order ID']}&force=1`, {
      method: 'POST',
    });
    const json = await res.json();
    await new Promise(resolve => setTimeout(resolve, 5000));
    // await get().getPipelinesByOrderId(order.ApiSlug || 'bg', order['Order ID']);
    return json.data;
  },
  deleteOrderByIds: async (orders: Array<TCMSOrder>, deletedBy) => {
    const groupBySlugs = get().clients.map(v => ({
      ...v,
      orders: orders.filter(o => o.ApiSlug === v.slug),
    })).filter(v => v.orders.length > 0);
    await Promise.all(groupBySlugs.map(async (v) => {
      try {
        v.orders.forEach(order => {
          Api.Log.addLog({
            type: LOG_TYPES.DELETE_ORDER,
            data: {
              orderId: order['Order ID'],
            },
            oldData: {
              inactive: false,
            },
            newData: {
              inactive: true,
            }
          })
        })
        await requestMicroApi(`/api/${v.slug}/deleteOrder`, {
          method: 'POST',
          body: JSON.stringify({
            ids: v.orders.map(o => o.Id),
            deletedBy,
          }),
        });
      } catch (err) { }
    }))
  },
  restoreOrderByIds: async (orders: Array<TCMSOrder>) => {
    const groupBySlugs = get().clients.map(v => ({
      ...v,
      orders: orders.filter(o => o.ApiSlug === v.slug),
    })).filter(v => v.orders.length > 0);
    await Promise.all(groupBySlugs.map(async (v) => {
      try {
        await requestMicroApi(`/api/${v.slug}/restoreOrder`, {
          method: 'POST',
          body: JSON.stringify({ ids: v.orders.map(o => o.Id) }),
        });
      } catch (err) { }
    }))
  },
  getRoyalMailOrderData: async (orders: Array<TCMSOrder>) => {
    const inProductionOrders = orders.filter(i => i.Pipelines?.length && (i.Stage === 'In Production' || i.Stage === 'Fulfillment'));
    if (!inProductionOrders?.length) return;
    const res = await Client.Api.Shop.updateInProductionOrders({
      orders: inProductionOrders,
    })
    if (res.data?.data) {
      res.data?.data.forEach((order) => {
        const oldOrder = orders.find(i => i["Order ID"] === order["Order ID"]);
        if (
          oldOrder.Status !== order.Status ||
          oldOrder.Stage !== order.Stage ||
          oldOrder.StageStatus !== order.StageStatus
        ) {
          GlobalEvent.emit('ORDER_UPDATE_TO_FULFILLED', {
            orderId: order["Order ID"],
            orderData: order
          });
        }
      })
    }
  },
  getChargeAmount: async (orders: Array<TCMSOrder>) => {
    const res = await Client.Api.Payment.getChargeAmountFromLineItems({
      orders: orders.map(i => {
        const last = i.Pipelines[i.Pipelines.length - 1];
        return {
          line_items: last?.SharedData?.canBeProcessedItems || [],
          shipping_address: i['Raw Data'].shipping_address,
          orderId: i['Order ID'],
        };
      })
    });
    return res.data.data;
  },
  getChargeAmountOrderSample: async (orders: TCMSOrder[]) => {
    const res = await Client.Api.Payment.getChargeAmountFromLineItems({
      orders: orders.map(order => ({
        line_items: order["Raw Data"].line_items,
        shipping_address: order["Raw Data"].shipping_address,
        orderId: order["Order ID"],
      }))
    });
    return res.data.data;
  },
  chargeFromWallet: async (orders: Array<TCMSOrder>, shippingService: string) => {
    const res = await Client.Api.Payment.chargeFromWallet({
      orders: orders.map(v => {
        const last = v.Pipelines[v.Pipelines.length - 1]?.SharedData;
        return {
          orderId: v['Order ID'],
          orderNumber: v['Order Number'],
          line_items: last?.canBeProcessedItems, // last.reprint ? [] : (last?.canBeProcessedItems || []) as Array<any>,
          slug: v.ApiSlug,
          shipping_address: v['Raw Data'].shipping_address,
        };
      }),
      shippingService,
    });

    if (res.data.success) {
      await get().updateOrderStage(orders, {
        StageStatus: "Queued For Production"
      });
      await Promise.all(orders.map(async (order) => {
        await get().updateShareData(order, {
          isPaid: true,
          isAdminApproved: false,
        });
      }));
    }
    if (res.data.error) {
      showPopupMessage({
        title: '',
        content: String(res.data.error),
        buttonOkText: 'OK',

        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
      // alert(res.data.error);
    }

    return res.data.data;
  },
  chargeFromWalletByStripeInvoice: async (orders: Array<TCMSOrder>, invoiceIds: Array<string>, shippingService: string, useSampleToken?: boolean) => {
    const res = await Client.Api.Payment.chargeByStripeInvoice({
      invoiceIds,
      shippingService,
      useSampleToken,
    });

    if (res.data.success) {
      await get().updateOrderStage(orders, {
        StageStatus: "Queued For Production"
      });
      await Promise.all(orders.map(async (order) => {
        await get().updateShareData(order, {
          isPaid: true,
          isAdminApproved: false,
        });
      }));
    }
    if (res.data.error) {
      showPopupMessage({
        title: '',
        content: String(res.data.error),
        buttonOkText: 'OK',

        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
      // alert(res.data.error);
    }

    return res.data.data;
  },
  updateReportDataManually: (data: any) => {
    set({
      reportData: {
        ...get().reportData,
        ...data,
      }
    })
  }
}));
