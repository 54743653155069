import Request from '../Request.utils'
export interface IListOrderApiRequestParams {
  clientId?: string;
  dateType?: string;
  endDate?: string;
  env?: string;
  filter?: string;
  inactive?: '';
  includeInactive?: '';
  limit: number;
  offset: number;
  orderId?: string;
  orderNumber?: string;
  orderType?: string;
  sort?: string;
  stage?: string;
  stageStatus?: string;
  startDate?: string;
  status?: string;
  supported?: '';
}
export interface IUpdatePipelineShareDataApiRequestBody {
  data?: any;
  pipelineId: string;
}
export interface IUpdateOrderStatusApiRequestBody {
  Stage?: string;
  StageStatus?: string;
  Status?: string;
}
export interface IUpdateOrderStatusApiRequestParams {
  id: string;
  orderId: string;
}
export interface IListPipelineApiRequestQuery {
  endDate?: string;
  limit: number;
  offset: number;
  orderId?: string;
  searchByOrderId?: string;
  sort?: string;
  startDate?: string;
}
export interface ITriggerDispatchRoyalMailApiRequestParams {
  force?: boolean;
  id?: string;
  orderId?: string;
  serviceCode?: string;
}
export interface IRerunJobApiRequestBody {
  forceSkipCallback?: boolean;
  jobId: string;
  pipelineId: number;
  thisJobOnly?: boolean;
}
export interface IRunJobApiRequestBody {
  additionData?: any;
  error?: string;
  jobId: string;
  pipelineId: number;
  thisJobOnly?: boolean;
}
export interface IContinuePipelineApiRequestBody {
  pipelineId: number;
}
export interface IRequestGeneratePDFCallbackApiRequestBody {
  error?: string;
  pdfs?: any;
}
export interface IRequestGeneratePDFCallbackApiRequestParams {
  orderId: string;
}


class BgAPI {
  listOrderApi = async (params: IListOrderApiRequestParams) => {
    const res = await Request.call('/api/bg/listOrder', 'GET', params, undefined, undefined, );
    return res;
  }
  updatePipelineShareDataApi = async (body: IUpdatePipelineShareDataApiRequestBody) => {
    const res = await Request.call('/api/bg/pipeline/update-pipeline-share-data', 'POST', undefined, undefined, body, );
    return res;
  }
  updateOrderStatusApi = async (params: IUpdateOrderStatusApiRequestParams, body: IUpdateOrderStatusApiRequestBody) => {
    const res = await Request.call('/api/bg/updateOrderStatus', 'POST', params, undefined, body, );
    return res;
  }
  listPipelineApi = async (query: IListPipelineApiRequestQuery) => {
    const res = await Request.call('/api/bg/pipeline/list', 'GET', undefined, query, undefined, );
    return res;
  }
  triggerDispatchRoyalMailApi = async (params: ITriggerDispatchRoyalMailApiRequestParams) => {
    const res = await Request.call('/api/bg/trigger-dispatch-royalmail', 'POST', params, undefined, undefined, );
    return res;
  }
  rerunJobApi = async (body: IRerunJobApiRequestBody) => {
    const res = await Request.call('/api/bg/pipeline/rerun-job', 'POST', undefined, undefined, body, );
    return res;
  }
  runJobApi = async (body: IRunJobApiRequestBody) => {
    const res = await Request.call('/api/bg/pipeline/run-job', 'POST', undefined, undefined, body, );
    return res;
  }
  continuePipelineApi = async (body: IContinuePipelineApiRequestBody) => {
    const res = await Request.call('/api/bg/pipeline/continue', 'POST', undefined, undefined, body, );
    return res;
  }
  requestGeneratePDFCallbackApi = async (params: IRequestGeneratePDFCallbackApiRequestParams, body: IRequestGeneratePDFCallbackApiRequestBody) => {
    const res = await Request.call('/api/bg/request-generate-pdf-callback', 'POST', params, undefined, body, );
    return res;
  }
}
export default new BgAPI()